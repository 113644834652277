import React from "react";
import { StaticQuery, graphql } from 'gatsby';
import { Faq } from './faq';

export const FaqRepeater = () => (

  <StaticQuery
    query={
      graphql`
      query faqsQuery {
        sanityFaqPage {
          faqs {
            _key
            answer
            question
          }
        }
      }
      `
    }
    render={data => (
      <div className="mt-20">
        {data.sanityFaqPage.faqs.map(f => {
          return (
            <Faq faq={f} key={f._key} />
          )
        })}
      </div>
    )}

  />
);



import React, { useState, useRef, useEffect } from 'react';
import { StyledFAQ } from './styledFaq';


export const Faq = ({ faq }) => {
    const [menuActive, setMenuState] = useState(false);
    const [maxHeight, setMaxHeight] = useState(0);
    const panel = useRef();

    // can just do this in the onClick={() => setMenuState(!menuActive)}
    function setMenuActive(){
        setMenuState(!menuActive);
    }

    // using useEffect and passing in menuActive state so it gets called after the state has changed async
    useEffect(() => {
        menuActive 
        ? setMaxHeight(panel.current.scrollHeight)
        : setMaxHeight(0);
      }, [menuActive]);
  
    return (
        <StyledFAQ   >
            <button className={`accordion ${menuActive ? 'active' : ''}`} onClick={setMenuActive} >{faq.question}</button>
            <div className="panel text-black" style={{maxHeight: `${maxHeight}px`}} ref={panel}>
                <p>
                    {faq.answer}
                </p>
            </div>
        </StyledFAQ>
    );
};

